import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { Element } from "react-scroll";
import DocumentMetadata from "components/DocumentMetadata";
import AutoSaveLoader from "components/AutoSaveLoader";
import ReactRouterPropTypes from "react-router-prop-types";
import { BekreftetStatus, PubliseringsStatus } from "constants/serverEnums";
import { institusjonLongName } from "logic/initialState";
import { hoeringShape, notatShape } from "utils/PropTypes";
import { getNotat, getNextNotat } from "ducks/hoeringDuck";
import { sendInnPopinId, lagreUtkastPopinId, videresendUtkastPopinId } from "constants/popinIds";
import Footer from "components/Footer";
import { getLocaleCode } from "utils/localeUtils";
import TutorialOverlay from "./components/TutorialOverlay";
import InnsendtHeader from "./components/hoeringsUttalelse/InnsendtHeader";
import IngenMerknader from "./components/hoeringsUttalelse/IngenMerknader";
import IngenMerknaderManueltRegistrert from "./components/hoeringsUttalelse/IngenMerknaderManueltRegistrert";
import Innholdsfortegnelse from "./components/Innholdsfortegnelse";
import LagreOgGaTilbake from "./components/hoeringsUttalelse/LagreOgGaTilbake";
import LagreOgSendInn from "./components/hoeringsUttalelse/LagreOgSendInn";
import SeksjonUttalelse from "./components/uttalelse/SeksjonUttalelse";
import NotatUttalelse from "./components/uttalelse/NotatUttalelse";
import Signatur from "./components/hoeringsUttalelse/Signatur";
import Fotnoter from "./components/hoeringsUttalelse/Fotnoter";
import Vedlegg from "./components/hoeringsUttalelse/Vedlegg";
import SendInnPopin from "./components/sendInn/SendInnPopin";
import LagreUtkastPopin from "./components/lagreUtkast/LagreUtkastPopin";
import VideresendUtkastPopin from "./components/videresendUtkast/VideresendUtkastPopin";
import MessageBanner from "./components/hoeringsUttalelse/MessageBanner";
import Header from "./components/hoeringsUttalelse/Header";
import Sidebar from "./components/sidebar/Sidebar";
import * as uttalelseActions from "./uttalelseActions";

class HoeringsUttalelse extends PureComponent {
  componentDidMount() {
    if (!this.props.currentNotat) {
      this.props.actions.navigateToNotat(this.props.hoering.notat[0].id);
    }

    document.addEventListener("click", this.onClick);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentNotat) {
      nextProps.actions.navigateToNotat(nextProps.hoering.notat[0].id);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClick);
  }

  onClick = (e) => {
    if (e.target.classList.contains("fotnote")) {
      e.preventDefault();
      this.props.actions.navigateToFootnote(e.target.dataset.fotnoteId, this.props.currentNotat.id);
    }
  };

  render() {
    const { hoering, uttalelse, currentNotat, nextNotat, actions, location, hasFeedback, locale } = this.props;

    if (!currentNotat) return null;

    const {
      frist,
      publisert,
      svarBekreftelse,
      innsender,
      endret,
      hoeringId,
      eksternId,
      innsendt,
      readOnly,
      manuellRegistreringsKode,
      publiseringsStatus,
      forhandsvisning,
      utgatt,
      manueltRegistrert,
      showTutorialOverlay,
      sendInnKunSisteNotat,
      enSakPerSvarInnstilling,
    } = hoering;

    const { saksNr, avsender, tittel, fotnoter } = currentNotat;
    const editManueltRegistert = manueltRegistrert && !!manuellRegistreringsKode;
    const arkivnummer = innsendt && !editManueltRegistert && enSakPerSvarInnstilling ? hoering.arkivnummer : saksNr;

    const pageClass = classnames({
      Page: true,
      "Page--readonly": readOnly || innsendt,
      "Page--innsendt": innsendt && !editManueltRegistert,
    });

    if (!forhandsvisning && publiseringsStatus === PubliseringsStatus.IkkePublisert) {
      return (
        <>
          <DocumentMetadata title={institusjonLongName} />
          <div className="Grid">
            <div className={pageClass}>
              <Element name="headerScroll" />
              <Header />
              <MessageBanner hoering={hoering} />
            </div>
          </div>
          <Footer hoeringId={hoeringId} />
        </>
      );
    }

    const hasSidebar = hoering.notat.filter((n) => !innsendt || n.uttalelse.hasFeedback || n.rekkefolge === 0).length > 1;
    const gridClass = classnames({
      Grid: true,
      "Grid--offset": hasSidebar || (editManueltRegistert && hoering.notat.length > 1),
      "Grid--innsendt": innsendt && !editManueltRegistert,
    });

    const notatHasSporsmals =
      currentNotat !== hoering.notat[0] ||
      currentNotat.kanHaGenerellHoldning ||
      currentNotat.kanHaGenerellKommentar ||
      currentNotat.seksjons.some((s) => s.kanHaMarkering) ||
      currentNotat.seksjons.some((s) => s.sporsmals.length > 0);

    return (
      <>
        <DocumentMetadata title={tittel[getLocaleCode(locale)] ?? ""} />
        <AutoSaveLoader />
        <div className={gridClass}>
          <Sidebar
            currentNotat={currentNotat}
            notater={hoering.notat}
            location={location}
            endret={endret}
            innsendt={innsendt && !editManueltRegistert}
            showTimestamp={!innsendt && !readOnly}
          />
          <div className={pageClass}>
            <header>
              <Element name="headerScroll" />
              <InnsendtHeader
                show={innsendt && !editManueltRegistert}
                innsender={innsender}
                eksternId={eksternId}
                navnSkjult={hoering.navnSkjult}
              />

              <Header avsender={avsender} frist={frist} publisert={publisert} referanse={arkivnummer} />
            </header>
            <main id="main">
              <div className="Page-section">
                <h1 className="Page-title u-pageTitle">{tittel[getLocaleCode(locale)] ?? ""}</h1>
              </div>

              <MessageBanner hoering={hoering} editManueltRegistert={editManueltRegistert} />

              {editManueltRegistert ? (
                <IngenMerknaderManueltRegistrert
                  onClick={(ingenMerknader) => () =>
                    actions.ingenMerknaderManuellRegistrert(hoering.eksternId, manuellRegistreringsKode, ingenMerknader)
                  }
                  kanHaIngenMerknader={hoering.ingenMerknader.enabled}
                  harIngenMerknader={uttalelse.ingenMerknader}
                />
              ) : (
                <IngenMerknader
                  readonly={innsendt || readOnly}
                  onClick={actions.ingenMerknader}
                  hasFeedback={hasFeedback}
                  kanHaIngenMerknader={hoering.ingenMerknader.enabled}
                  harIngenMerknader={uttalelse.ingenMerknader}
                />
              )}

              {currentNotat.visInnholdsfortegnelse && (
                <Innholdsfortegnelse seksjoner={currentNotat.seksjons} skjulNummerering={currentNotat.skjulNummerering} />
              )}

              <SeksjonUttalelse
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                updateSporsmalUttalelse={actions.updateSporsmalUttalelse}
                updateSeksjonUttalelse={actions.updateSeksjonUttalelse}
                seksjons={currentNotat.seksjons}
                skjulNummerering={currentNotat.skjulNummerering}
                forhandsvisning={(utgatt && !innsendt) || forhandsvisning || editManueltRegistert}
              />

              <NotatUttalelse
                readonly={innsendt || readOnly}
                editManueltRegistert={editManueltRegistert}
                notat={currentNotat}
                updateNotatUttalelse={actions.updateNotatUttalelse}
                forhandsvisning={(utgatt && !innsendt) || forhandsvisning || editManueltRegistert}
              />

              <Signatur signatur={currentNotat.signatur} />

              <Fotnoter fotnoter={fotnoter} notatId={currentNotat.id} />

              <Vedlegg vedlegg={hoering.vedlegg} notatVedlegg={currentNotat.vedlegg} />

              {editManueltRegistert ? (
                <LagreOgGaTilbake
                  actions={actions}
                  hoering={hoering}
                  uttalelse={uttalelse}
                  manuellRegistreringsKode={manuellRegistreringsKode}
                  returnTo={new URLSearchParams(location.search).get("returnTo")}
                />
              ) : (
                <LagreOgSendInn
                  actions={actions}
                  innsendt={innsendt}
                  forhandsvisning={forhandsvisning}
                  hasFeedback={hasFeedback}
                  readOnly={readOnly}
                  nextNotat={nextNotat}
                  currentNotat={currentNotat}
                  sendInnKunSisteNotat={sendInnKunSisteNotat}
                  notatHasSporsmals={notatHasSporsmals}
                />
              )}

              <SendInnPopin id={sendInnPopinId} eksternId={eksternId} />
              <LagreUtkastPopin id={lagreUtkastPopinId} eksternId={eksternId} />
              <VideresendUtkastPopin id={videresendUtkastPopinId} eksternId={eksternId} />
            </main>
          </div>
        </div>
        <Footer hoeringId={hoeringId} />
        <TutorialOverlay
          eksternId={eksternId}
          isOpen={innsendt && svarBekreftelse === BekreftetStatus.IkkeBekreftet && showTutorialOverlay}
          epost={innsender.epost}
          innsendtDato={innsender.innsendtDato}
        />
      </>
    );
  }
}

HoeringsUttalelse.propTypes = {
  hoering: hoeringShape.isRequired,
  currentNotat: notatShape.isRequired,
  nextNotat: notatShape.isRequired,
  actions: PropTypes.object.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  hasFeedback: PropTypes.bool,
  uttalelse: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(uttalelseActions, dispatch),
  };
};

const mapStateToProps = (state, ownProps) => {
  const notatsWithFeedback = state.hoering.notat.filter((n) => n.uttalelse.hasFeedback);
  const selectedNotat = notatsWithFeedback.length > 0 ? notatsWithFeedback[0] : state.hoering.notat[0];
  const notatId = ownProps.notatParam ? parseInt(ownProps.notatParam, 10) : selectedNotat.id;

  return {
    currentNotat: getNotat(notatId, state.hoering),
    nextNotat: getNextNotat(notatId, state.hoering),
    hoering: state.hoering,
    hasFeedback: state.uttalelse.hasFeedback,
    uttalelse: state.uttalelse,
    locale: state.locale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HoeringsUttalelse);
